@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary-dark: #2b7129;
  --primary-light: #0DC118;
}

@layer base {
  body {
    @apply font-[Poppins];
  }

  nav li {
    @apply p-4 text-xl;
  }

  nav h1 {
    @apply text-3xl md:text-4xl font-bold;
  }

  nav h2 {
    @apply text-2xl md:text-3xl font-bold;
  }

  nav h3 {
    @apply text-xl md:text-2xl font-bold;
  }

  nav button {
    @apply p-3 border bg-gradient-to-r from-[var(--primary-dark)] to-[var(--primary-light)] text-white rounded-md;
  }

  .hero button {
    @apply bg-gradient-to-r from-[var(--primary-dark)] to-[var(--primary-light)] py-5 w-80 rounded-full text-black font-bold shadow-lg;
  }
}
